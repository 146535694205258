import { gql } from 'graphql-request';
import baseApiAuthorized from '@store/api/BaseApiAuthorized';
import { getCopiersQueueDetails } from '@features/portfolio/lib';
import mapPortfoliosList from './utils';
import { getCurrentPositions, getPortfolio, getPositionsHistory, getProfilePortfolios, } from './mocks';
const portfoliosApi = baseApiAuthorized.injectEndpoints({
    endpoints: (builder) => ({
        getPopularList: builder.query({
            query: () => ({
                url: '',
                // TODO (maybe) decompose body into gql fragments?
                body: gql `
                    query {
                        allPortfolios {
                            id
                            name
                            monthlyFee
                            createdAt
                            exchangeConnection {
                                id
                            }
                            portfolioview {
                                id
                            }
                            portfoliocomment {
                                id
                            }
                            profile {
                                id
                                nickname
                            }
                        }
                    }
                `,
                transformResponse: mapPortfoliosList,
            }),
            providesTags: ['PopularPortfolios'],
            transformResponse: (data) => mapPortfoliosList(data),
        }),
        getPortfolio: builder.query({
            query: ({ portfolioId }) => ({
                url: '',
                body: gql `
                    query {
                        myUser {
                            id
                        }
                    }
                `,
            }),
            providesTags: (result, error, { portfolioId }) => [{ type: 'Portfolios', id: portfolioId }],
            // eslint-disable-next-line max-len
            transformResponse: () => getPortfolio(),
        }),
        getProfilePortfolios: builder
            .query({
            query: ({ profileId }) => ({
                url: '',
                body: gql `
                    query {
                        myUser {
                            id
                        }
                    }
                `,
            }),
            providesTags: (result) => (result ?
                [...result.map(p => ({ type: 'Portfolios', id: p.id })), 'Portfolios'] :
                ['Portfolios']),
            // eslint-disable-next-line max-len
            transformResponse: () => getProfilePortfolios(),
        }),
        getPortfolioCopiers: builder.query({
            query: ({ portfolioId, skip, take }) => ({
                url: '',
                body: gql `
                    query {
                        myUser {
                            id
                        }
                    }
                `,
            }),
            providesTags: (result) => (result ?
                [
                    ...result.map(c => ({ type: 'PortfolioCopiers', id: c.id })),
                    'PortfolioCopiers',
                ] :
                ['PortfolioCopiers']),
            // eslint-disable-next-line max-len
            transformResponse: (response, meta, { skip, take, sortByDelay }) => [],
        }),
        getPortfolioCopiersQueueDetails: builder
            .query({
            query: ({ portfolioId }) => ({
                url: '',
                body: gql `
                    query {
                        myUser {
                            id
                        }
                    }
                `,
            }),
            // eslint-disable-next-line max-len
            transformResponse: () => getCopiersQueueDetails(),
        }),
        startCopying: builder.mutation({
            query: ({ portfolioId }) => ({
                url: '',
                body: gql `
                    query {
                        myUser {
                            id
                        }
                    }
                `,
            }),
            invalidatesTags: ['PortfolioCopiers'],
        }),
        getPortfolioCurrentPositions: builder
            .query({
            query: ({ portfolioId, skip, take, sortByPnl, }) => ({
                url: '',
                body: gql `
                    query {
                        myUser {
                            id
                        }
                    }
                `,
            }),
            // eslint-disable-next-line max-len
            transformResponse: (response, meta, { skip, take, sortByPnl }) => getCurrentPositions(skip, take, sortByPnl),
        }),
        getPortfolioPositionsHistory: builder
            .query({
            query: ({ portfolioId, skip, take, }) => ({
                url: '',
                body: gql `
                    query {
                        myUser {
                            id
                        }
                    }
                `,
            }),
            // eslint-disable-next-line max-len
            transformResponse: (response, meta, { skip, take }) => getPositionsHistory(skip, take),
        }),
        savePortfolio: builder.mutation({
            query: ({ portfolioId, body }) => ({
                url: '',
                body: gql `
                    query {
                        myUser {
                            id
                        }
                    }
                `,
            }),
            invalidatesTags: (result, error, args) => [{ type: 'Portfolios', id: args.portfolioId }],
        }),
    }),
});
export default portfoliosApi;
export const { useGetPopularListQuery, useLazyGetPopularListQuery, useGetPortfolioCopiersQuery, useGetPortfolioCopiersQueueDetailsQuery, useStartCopyingMutation, useGetPortfolioCurrentPositionsQuery, useGetPortfolioPositionsHistoryQuery, useSavePortfolioMutation, useGetPortfolioQuery, useGetProfilePortfoliosQuery, useLazyGetProfilePortfoliosQuery, } = portfoliosApi;
