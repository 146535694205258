import { createSlice } from '@reduxjs/toolkit';
import { getSidebarInitValues, mapWalletsOverview } from './utils';
import { ExchangeSidebar, } from './types';
import portfoliosApi from '../portfolio/api';
import { getExchanges, getWalletsWithExchanges } from './thunks';
const SliceName = 'exchange';
const initialState = {
    isSidebarOpen: false,
    sidebarType: null,
    sidebarFormValues: null,
    selectedExchange: null,
    selectedPortfolio: null,
    exchangesList: {
        list: null,
        isLoading: false,
        hasError: false,
    },
    portfoliosList: null,
    walletsOverviewList: {
        list: null,
        isLoading: false,
        hasError: false,
    },
    walletsForPortfolioTab: {
        list: null,
        isLoading: false,
        hasError: false,
    },
};
const exchangeSlice = createSlice({
    name: SliceName,
    initialState,
    reducers: {
        openSidebar: (state, { payload }) => {
            if (payload !== ExchangeSidebar.CreatePortfolio) {
                state.isSidebarOpen = true;
                state.sidebarType = payload;
            }
            state.sidebarFormValues = getSidebarInitValues(payload, state.exchangesList.list || []);
        },
        closeSidebar: (state) => {
            state.isSidebarOpen = false;
            state.sidebarType = null;
            state.sidebarFormValues = null;
            state.selectedExchange = null;
            state.selectedPortfolio = null;
        },
        selectExchange: (state, { payload }) => {
            if (state.exchangesList.list) {
                state.selectedExchange = state.exchangesList.list.find((exchange) => exchange.id === payload);
            }
        },
        selectPortfolio: (state, { payload }) => {
            if (state.portfoliosList) {
                state.selectedPortfolio = state.portfoliosList.find((portfolio) => portfolio.id === payload);
            }
        },
        removeSelectedExchange: (state) => {
            state.selectedExchange = null;
        },
        updateSidebarValues: (state, { payload }) => {
            state.sidebarFormValues = Object.assign(Object.assign({}, state.sidebarFormValues), payload);
        },
        reset: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(getWalletsWithExchanges.pending, (state) => {
            state.exchangesList.isLoading = true;
            state.walletsForPortfolioTab.isLoading = true;
            state.walletsOverviewList.isLoading = true;
        });
        builder.addCase(getExchanges.fulfilled, (state, { payload }) => {
            state.exchangesList.list = payload;
            state.exchangesList.isLoading = false;
        });
        builder.addCase(getExchanges.rejected, (state) => {
            state.exchangesList.isLoading = false;
            state.exchangesList.hasError = true;
        });
        builder.addCase(getWalletsWithExchanges.fulfilled, (state, { payload }) => {
            const { walletsForPortfolio, walletsOverviewList, } = mapWalletsOverview(payload.walletsList, payload.exchanges || []);
            state.walletsOverviewList.list = walletsOverviewList;
            state.walletsOverviewList.isLoading = false;
            state.walletsForPortfolioTab.list = walletsForPortfolio;
            state.walletsForPortfolioTab.isLoading = false;
        });
        builder.addCase(getWalletsWithExchanges.rejected, (state) => {
            state.walletsOverviewList.isLoading = false;
            state.walletsOverviewList.hasError = true;
            state.walletsForPortfolioTab.isLoading = false;
            state.walletsForPortfolioTab.hasError = true;
        });
        builder.addMatcher(portfoliosApi.endpoints.getProfilePortfolios.matchFulfilled, (state, { payload }) => {
            state.portfoliosList = payload;
        });
    },
});
export { SliceName };
export const { openSidebar, closeSidebar, updateSidebarValues, selectExchange, selectPortfolio, removeSelectedExchange, reset, } = exchangeSlice.actions;
export const exchangeReducer = exchangeSlice.reducer;
