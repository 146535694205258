import { createSlice } from '@reduxjs/toolkit';
import { PortfolioType } from '@entities/portfolio/model/Portfolio';
const SliceName = 'portfolioEdit';
var PortfolioEditViewMode;
(function (PortfolioEditViewMode) {
    PortfolioEditViewMode[PortfolioEditViewMode["View"] = 0] = "View";
    PortfolioEditViewMode[PortfolioEditViewMode["Edit"] = 1] = "Edit";
})(PortfolioEditViewMode || (PortfolioEditViewMode = {}));
var PortfolioVisibility;
(function (PortfolioVisibility) {
    PortfolioVisibility["Visible"] = "visible";
    PortfolioVisibility["Private"] = "private";
})(PortfolioVisibility || (PortfolioVisibility = {}));
var PortfolioBalanceType;
(function (PortfolioBalanceType) {
    PortfolioBalanceType["Percentage"] = "percentage";
    PortfolioBalanceType["Usdt"] = "usdt";
})(PortfolioBalanceType || (PortfolioBalanceType = {}));
const CreateEmptyPortfolioEditBody = () => ({
    parentExchangeId: -1,
    name: '',
    type: PortfolioType.Spot,
    visibility: PortfolioVisibility.Visible,
    referalLink: 'https://endrex.net',
    balance: PortfolioBalanceType.Usdt,
    monthlyFee: 0,
    feeCurrency: 'usd', // TODO: discuss
});
const portfolioEditSlice = createSlice({
    name: SliceName,
    initialState: {
        editBody: CreateEmptyPortfolioEditBody(),
    },
    reducers: {
        updateParentExchange: (state, action) => {
            state.editBody.parentExchangeId = action.payload;
        },
        updateName: (state, action) => {
            state.editBody.name = action.payload;
        },
        updateType: (state, action) => {
            state.editBody.type = action.payload;
        },
        updateVisibility: (state, action) => {
            state.editBody.visibility = action.payload;
        },
        updateMonthlyFee: (state, action) => {
            state.editBody.monthlyFee = action.payload;
        },
    },
});
export { SliceName, PortfolioEditViewMode, PortfolioType, PortfolioVisibility, PortfolioBalanceType, };
export const { updateParentExchange, updateName, updateType, updateVisibility, updateMonthlyFee, } = portfolioEditSlice.actions;
export default portfolioEditSlice.reducer;
